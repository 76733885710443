import { getTrackingServicesConfiguration } from '@/utils/captureException/utils';
import * as Sentry from '@sentry/nextjs';
import { datadogRum } from '@datadog/browser-rum';

export const captureException = (
  error: Error | unknown,
  tags: Record<string, string> | undefined = undefined,
  extras: Record<string, unknown> | undefined = undefined,
) => {
  const { sentryEnabled, datadogEnabled } = getTrackingServicesConfiguration();
  if (sentryEnabled) {
    Sentry.withScope((scope) => {
      if (tags) {
        scope.setTags({ ...tags });
      }
      if (extras) {
        scope.setExtras(extras);
      }
      Sentry.captureException(error);
    });
  }
  if (datadogEnabled) {
    datadogRum.addError(error, { ...tags, ...extras });
  }
};
