import type { AppContext, AppProps } from 'next/app';
import { useRef } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Cookie } from '@/types/cookie';
import { ErrorBoundary } from '@/components/ErrorBoundary/ErrorBoundary';
import { ErrorToast } from '@/components/ErrorToast/ErrorToast';
import { ErrorProvider } from '@/contexts/ErrorProvider/ErrorProvider';
import { RestaurantDateProvider } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';
import { IntlProvider } from 'react-intl';
import { Locale } from '@/types/locale';
import { NormalizedCacheObject } from '@apollo/client';
import { WidgetConfig, WidgetConfigProvider } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { WidgetTypeEnum, getSdk } from '@/graphql/client-backend.generated';
import { getApolloClientForBackend, useApollo } from '@/utils/apollo-client-utils';
import { getSupportedLocale, getTranslations, parseAcceptedLanguage } from '@/utils/i18n/i18n';
import { getCookie } from '@/utils/cookie-utils/cookie-utils';
import { parse as parseUrl } from 'url';
import { validate as isValidUUID } from 'uuid';
import { formatCloudinaryImageUrl } from '@/utils/format-cloudinary-image-utils';
import GlobalStyle from '@lafourchette/react-chili/dist/cjs/components/GlobalStyle';
import App from 'next/app';
import Head from 'next/head';
import getConfig from 'next/config';
import { initializeDatadog } from '@/services/datadog';

export interface CustomPageProps {
  locale: Locale;
  defaultLocale: Locale;
  messages: Record<string, string>;
  serverDateUTC: string;
  config?: WidgetConfig;
  __APOLLO_STATE__: NormalizedCacheObject;
}

export function isExcludedQuery(url: string, windowType: string): boolean {
  // We've got 2 issues here:
  // 1/ Everything defined in getInitialProps can be executed client side, therefore, everything is bundled for client
  //    (this means all translations for every language).
  // 2/ getInitialProps should be executed server side when initializing the app and then client side.
  //    Still, there is an exception for page with getServerSideProps, in this case getInitialProps from _app is executed
  //    server side as well (this means that all translations for current language is sent at every page transition to
  //    page with getServerSideProps).
  // -> We decided to go with this hack in order to reduce both bundle (faster first time loading time) and successive
  //    page load (faster communication at page transition).
  //
  // Hack found on https://github.com/vercel/next.js/discussions/18210#discussioncomment-1202376
  // Webpack will not include what's after this in client bundle.
  if (windowType !== 'undefined') {
    return true;
  }
  // Ensures that the expression under this condition will be executed only once on first page render.
  // If the url starts with /_next/data, that means that we have a client-side navigation situation requiring
  // some additional data from getServerSideProps or getInitialProps (not sure about this one).
  if (url.startsWith('/_next/data')) {
    return true;
  }
  return false;
}

/**
 * Used to compensate data loss between pages navigation, because of initialProps hack.
 * @param data - cached data
 */
export function useCache<T>(data: T): T {
  return useRef(data).current;
}

export function isLegacyUrl(url: string): boolean {
  return url.includes('/module/') || url.includes('/cta/');
}

export function getLegacyIdFromUrl(url: string): number | null {
  const legacyId = url.match(/\/(module|cta)\/(.+)-.+/)?.[2];
  return legacyId ? Number(legacyId) : null;
}

export async function getRestaurantUuidFromLegacyId(legacyId: number): Promise<string | null> {
  const apolloClient = getApolloClientForBackend();
  const graphQlSdk = getSdk(apolloClient);
  const { data, error } = await graphQlSdk.getRestaurantRolloutConfigurationQuery({
    variables: {
      legacyId,
    },
  });
  return error ? null : data.restaurantRolloutConfiguration.restaurantUuid;
}

type GetCurrentLanguageParams = {
  rawLocales: string[];
  rawLocale: string;
  rawCookie: string;
  rawHeaderAcceptLanguage: string;
  restaurantUuid: string;
  url: string;
};

export async function getCurrentLanguage({
  rawCookie,
  rawHeaderAcceptLanguage,
  rawLocale,
  rawLocales,
  restaurantUuid,
  url,
}: GetCurrentLanguageParams): Promise<string> {
  /*
    The NextJs detection works well at the root and redirect to the page but not on the page different than root.
    We define NextJs configuration with a "default" value. This value will be defined by NextJs if the page do not have
    an i18n redirect with a supported locale. In this case, we determine the locale to use on the application.
    https://github.com/vercel/next.js/discussions/18419
  */
  if (rawLocale !== 'default') {
    return rawLocale;
  }

  const supportedLocales = rawLocales as Locale[];

  const OLD_LOCALE_FORMAT = /^\/([a-z]{2}_[A-Z]{2})/;
  const oldLocale = url.match(OLD_LOCALE_FORMAT)?.[1].replace('_', '-');
  const localeFromOldLocale = getSupportedLocale(supportedLocales, oldLocale);

  if (localeFromOldLocale) {
    return localeFromOldLocale;
  }

  const cookieLanguage = getCookie(rawCookie, Cookie.LANGUAGE);
  const localeFromCookieLanguage = getSupportedLocale(supportedLocales, cookieLanguage);

  if (localeFromCookieLanguage) {
    return localeFromCookieLanguage;
  }

  const acceptLanguage = parseAcceptedLanguage(rawHeaderAcceptLanguage);
  const localeFromAcceptLanguage = getSupportedLocale(supportedLocales, acceptLanguage);

  if (localeFromAcceptLanguage) {
    return localeFromAcceptLanguage;
  }

  const {
    serverRuntimeConfig: { defaultApplicationLocale },
  } = getConfig();
  let currentLanguage = defaultApplicationLocale;

  if (!restaurantUuid || !isValidUUID(restaurantUuid)) {
    return currentLanguage;
  }

  try {
    const apolloClient = getApolloClientForBackend();
    const graphQlSdk = getSdk(apolloClient);
    const restaurantData = await graphQlSdk.getRestaurantQuery({
      variables: {
        restaurantUuid: restaurantUuid,
      },
    });
    const localeFromPreferredLanguage = getSupportedLocale(
      supportedLocales,
      restaurantData.data?.restaurant?.preferredLocale,
    );

    if (localeFromPreferredLanguage) {
      currentLanguage = localeFromPreferredLanguage;
    }
  } catch (error) {
    console.error(error);
  }

  return currentLanguage;
}

export const MyApp = ({ Component, pageProps }: AppProps<CustomPageProps>) => {
  initializeDatadog();
  // useRef is used to cache initialProps between each page navigation.
  const localeCache = useCache(pageProps.locale);
  const defaultLocaleCache = useCache(pageProps.defaultLocale);
  const messagesRef = useCache(pageProps.messages);
  const configRef = useCache(pageProps.config);
  const serverDateUtcRef = useCache(pageProps.serverDateUTC);
  const apolloClient = useApollo(pageProps);

  const isWidgetCustomizable = configRef?.settings?.type === WidgetTypeEnum.White;

  return (
    <>
      <Head>
        <link rel="apple-touch-icon-precomposed apple-touch-icon" href="/apple-touch-icon-precomposed.png" />
        {isWidgetCustomizable && configRef?.designKit?.images.logo?.cloudinaryUrl ? (
          <link
            rel="shortcut icon"
            href={formatCloudinaryImageUrl({
              url: configRef?.designKit?.images.logo?.cloudinaryUrl,
              strategy: 'pad',
              width: '100',
              height: '100',
            })}
          />
        ) : (
          <>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="shortcut icon" href="/favicon.ico" />
          </>
        )}
        <link crossOrigin="use-credentials" rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00665C" />
        <meta name="msapplication-TileColor" content="#00666C" />
        <meta name="theme-color" content="#00666C" />
        <meta name="viewport" content="width=device-width" />
      </Head>
      <IntlProvider defaultLocale={defaultLocaleCache} locale={localeCache} messages={messagesRef}>
        <GlobalStyle config={{ fontPath: `/fonts/` }}>
          <WidgetConfigProvider widgetConfig={configRef}>
            <RestaurantDateProvider serverDateUTC={serverDateUtcRef}>
              <ErrorBoundary module="bookingWizard">
                <ApolloProvider client={apolloClient}>
                  <ErrorProvider>
                    <Component {...pageProps} />
                    <ErrorToast />
                  </ErrorProvider>
                </ApolloProvider>
              </ErrorBoundary>
            </RestaurantDateProvider>
          </WidgetConfigProvider>
        </GlobalStyle>
      </IntlProvider>
      {/* LaunchDarkly wrapper triggers a call to LaunchDarkly to identify and subscribe to events even if the flags are not used (removing the wrapper due to the issue faced)
      <LaunchDarklyWrapper>
      </LaunchDarklyWrapper> */}
    </>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { pathname, search, href } = parseUrl(appContext.ctx.req?.url || '');
  const appProps = await App.getInitialProps(appContext);
  // Filter out excluded queries.
  if (isExcludedQuery(href, typeof window)) {
    return { ...appProps };
  }

  const emptyResponse = {};
  let redirectionUrl = href;

  // Handle legacy URL.
  if (isLegacyUrl(href)) {
    const legacyId = getLegacyIdFromUrl(pathname || '');
    if (!legacyId) {
      return emptyResponse;
    }
    const restaurantUuid = await getRestaurantUuidFromLegacyId(legacyId);
    if (!restaurantUuid) {
      return emptyResponse;
    }
    redirectionUrl = `/${restaurantUuid}${search ?? ''}`;
  }

  // Handle language from URL.
  const currentLanguage = await getCurrentLanguage({
    rawCookie: appContext.ctx.req?.headers.cookie ?? '',
    rawHeaderAcceptLanguage: appContext.ctx.req?.headers['accept-language'] ?? '',
    rawLocale: appContext.ctx.locale ?? '',
    rawLocales: appContext.ctx.locales ?? [],
    restaurantUuid: String(appContext.router.query.restaurantUuid ?? ''),
    url: pathname ?? '',
  });
  if (currentLanguage !== appContext.ctx.locale && currentLanguage !== '') {
    redirectionUrl = `/${currentLanguage}` + redirectionUrl;
  }

  // Handle redirection if URL changed.
  if (href !== redirectionUrl) {
    appContext.ctx.res?.writeHead(302, { Location: redirectionUrl });
    appContext.ctx.res?.end();
    return emptyResponse;
  }

  // Get translations.
  const {
    serverRuntimeConfig: { defaultApplicationLocale },
  } = getConfig();
  const { messages, messagesLocale } = await getTranslations(currentLanguage, defaultApplicationLocale);

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      locale: messagesLocale,
      messages,
      defaultLocale: defaultApplicationLocale,
      // eslint-disable-next-line no-restricted-syntax
      serverDateUTC: new Date().toISOString(), // Only executed on server side.
    },
  };
};

// export default withLaunchDarklyProvider(MyApp);
export default MyApp;
